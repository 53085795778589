export const CURRENCIES = {
  USDTE: {
    name: 'USDT (ERC-20)',
  },
  USDTT: {
    name: 'USDT (TRC-20)',
  },
  USDC: {
    name: 'USDC (ERC-20)',
  },
  USDCS: {
    name: 'USDC (Solana)',
  },
  USDTS: {
    name: 'USDT (Solana)',
  },
}
