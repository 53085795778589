import React from 'react'

import { getShortLanguageIso } from 'utils/localisationHelper'

import { Grid } from '@coinspaid-hub/ui-kit'
import { SelectPopped } from '@coinspaid-hub/ui-kit/src/components/Lab'
import Icons from '@processing/coinspaid-icons'
import FlagIcons from '@processing/flag-icons'
import { LANGUAGE_NAMES } from 'constants/localisationConstants'

import useLanguage from 'hooks/useLanguage'

import { SEARCHABLE_MENU_MAX_HEIGHT } from './constants'
import { getIsLocalesSearchable, requestList } from './utils'

const valueRenderer = (props) => {
  const { value } = props

  return getShortLanguageIso(value)
}

const LanguageSwitcher = () => {
  const {
    language,
    languageList,
    onChangeLanguage,
    t,
  } = useLanguage()

  const menuItemsList = languageList.map((languageIso) => ({
    value: languageIso,
    label: (
      <Grid
        container
        alignItems='center'
      >
        <FlagIcons name={`${languageIso}_20`} />
        <Grid
          sx={{
            ml: 1.5,
          }}
        >
          {LANGUAGE_NAMES[languageIso]}
        </Grid>
      </Grid>
    ),
    nativeName: t(`views:language.${languageIso}`),
    englishName: t(`views:languageEnglish.${languageIso}`),
  }))

  const maxMenuHeight = getIsLocalesSearchable(menuItemsList.length) ? SEARCHABLE_MENU_MAX_HEIGHT : null

  return (
    <SelectPopped
      dataTestId='language-switcher'
      value={language}
      valueRenderer={valueRenderer}
      menuItemsList={menuItemsList}
      useDropdownIcon={false}
      requestList={requestList}
      maxMenuHeight={maxMenuHeight}
      search
      isScrollable
      buttonProps={{
        dataTestId: 'switch-locale',
        startIcon: <Icons name='M_LanuguageAlt' />,
        color: 'secondary',
      }}
      onChange={onChangeLanguage}
    />
  )
}

export default LanguageSwitcher
