import { MAX_LOCALES_WITHOUT_SEARCH } from './constants'

export const requestList = (searchValue = '', languagesList = []) => languagesList.filter((language) => {
  const {
    nativeName,
    englishName,
  } = language
  const lowerCasedSearchValue = searchValue.toLowerCase()

  return nativeName.toLowerCase().startsWith(lowerCasedSearchValue)
    || englishName.toLowerCase().startsWith(lowerCasedSearchValue)
})

export const getIsLocalesSearchable = (localesCount = 0) => localesCount > MAX_LOCALES_WITHOUT_SEARCH
