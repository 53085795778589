export const DEFAULT_LANGUAGE = 'en_US'
export const LANGUAGE_SEPARATOR = '_'
export const LOCALE_ISO_LENGTH = 5
export const LANGUAGE_TAG_REGEXP = /^[a-z]{2}_[A-Z]{2}/g

const LANGUAGE_NAMES = {
  en_US: 'English',
  ru_RU: 'Русский',
  es_ES: 'Español',
  uk_UA: 'Українська',
  uz_UZ: 'Uzbek',
  pt_PT: 'Português',
}

const DEFAULT_LANGUAGE_LIST = [
  'en_US',
  'es_ES',
  'ru_RU',
  'uk_UA',
  'uz_UZ',
  'pt_PT',
]

export {
  LANGUAGE_NAMES,
  DEFAULT_LANGUAGE_LIST,
}
