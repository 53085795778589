import { initReactI18next } from 'react-i18next'

import i18n, { use } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'

import { DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_LIST } from './constants/localisationConstants'

const settings = window.app_config
const initialLanguageSetting = settings?.initialLang || DEFAULT_LANGUAGE
const languages = settings?.languages || DEFAULT_LANGUAGE_LIST
const initialLang = initialLanguageSetting

const i18nInst = use(resourcesToBackend(
  (language, namespace) => import(`./translations/${language}/${namespace}.json`),
))
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'navigator', 'htmlTag'],
      lookupFromPathIndex: 0,
    },

    fallbackLng: initialLanguageSetting,
    lng: initialLanguageSetting,
    ns: [
      'forms',
      'views',
    ],
    defaultNS: 'views',
    keySeparator: true,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

const i18nTool = i18n
const languageList = languages
const initialLanguage = initialLanguageSetting

export {
  initialLanguage,
  languageList,
  initialLang,
  i18nTool,
}

export default i18nInst
