import Moment from 'moment'

import { LANGUAGE_SEPARATOR, LOCALE_ISO_LENGTH } from 'constants/localisationConstants'

export const getShortLanguageIso = (fullLanguageIso) => {
  if (!fullLanguageIso) {
    return ''
  }

  const splitIso = fullLanguageIso.split('_')
  const shortIso = splitIso[0]

  return shortIso.toUpperCase()
}

export const sortLanguage = (languageList) => languageList.sort(
  (language, nextLanguage) => language.localeCompare(nextLanguage),
)

export const getAccessibleLanguage = (defaultListLanguage, language) => (
  defaultListLanguage.find((defaultLanguage) => defaultLanguage.includes(language))
)

export const getUrlLanguage = (locale) => (locale && locale.length === LOCALE_ISO_LENGTH ? locale : null)

export const getLanguage = ({
  browserLanguage,
  urlLanguage,
  languageList: languageListProperty,
  initialLanguage: initialLanguageProperty,
}) => {
  const accessibleUrlLanguage = getAccessibleLanguage(languageListProperty, urlLanguage)
  const accessibleBrowserLanguage = getAccessibleLanguage(languageListProperty, browserLanguage)

  switch (true) {
    case !!accessibleUrlLanguage:
      return accessibleUrlLanguage
    case !!accessibleBrowserLanguage:
      return accessibleBrowserLanguage
    default:
      return initialLanguageProperty
  }
}

export const changeDataLocale = (locale) => Moment.locale(locale.split(LANGUAGE_SEPARATOR)[0])
